import React from "react"
import query from "query-string"
import { Helmet } from "react-helmet"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { settings } from "boostly-ui2"
import { create, inform, reportMissingParams } from "../api"
import Layout from "../components/Layout"
import EmojiMenu from "../components/EmojiMenu"
import Card from "../components/Card"
import { setState } from "../state"
import Underscore from "../components/Underscore"
import Title from "../components/Title"

const required = [`locationId`, `phone`, `experienceType`, `googlePlaceId`]
const getMissingParams = params => {
  let missing = []
  required.forEach(key => {
    if (!params[key]) {
      missing.push(key)
    }
  })
  return missing
}

const IndexPage = props => {
  const params = query.parse(props.location.search)
  const queryString = `?googlePlaceId=${params.googlePlaceId}`
  const onSelect = rating => {
    const missingParams = getMissingParams(params)
    if (missingParams.length) {
      reportMissingParams(missingParams)
      return
    }

    const review = {
      ...params,
      rating,
    }

    create({ review })
      .then(({ data }) => {
        const { status, result } = data
        if (status === `succeeded`) {
          setState({
            reviewId: result.reviewId,
          })
        } else {
          inform({
            message: `Reviews: create endpoint returned status other than 'succeeded' => ${status}`,
          })
        }
      })
      .catch(err =>
        inform({
          message: `Reviews: creation endpoint failed`,
        })
      )
  }
  return (
    <Layout bgShape={require(`../images/shape1.svg`)}>
      <Helmet>
        <meta property="og:title" content="How was your experience?" />
        <meta
          property="og:image"
          content="https://firebasestorage.googleapis.com/v0/b/boostly-live.appspot.com/o/assets%2Fimages%2Fexp.png?alt=media&token=05367814-154c-42bf-b5b0-821484be0805"
        />
      </Helmet>
      <Card
        gradient={{ to: settings.colors.blue, from: settings.colors.purple }}
      >
        <Title>How would you rate your experience?</Title>
        <Underscore />
        <EmojiMenu>
          {({ Poor, Satisfactory, Amazing }) => (
            <>
              <AniLink
                paintDrip
                to={`/1${queryString}`}
                hex={settings.colors.red}
                duration={0.35}
              >
                <Poor onSelect={onSelect} />
              </AniLink>
              <AniLink
                paintDrip
                to={`/2${queryString}`}
                hex={settings.colors.purple}
                duration={0.35}
              >
                <Satisfactory onSelect={onSelect} />
              </AniLink>
              <AniLink
                paintDrip
                to={`/3${queryString}`}
                hex={settings.colors.blue}
                duration={0.35}
              >
                <Amazing onSelect={onSelect} />
              </AniLink>
            </>
          )}
        </EmojiMenu>
      </Card>
    </Layout>
  )
}

export default IndexPage
